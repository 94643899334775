<template>
  <div class="stamps">
    <div class="describe">
      <div class="describe-title">活动简介</div>
      <div class="describe-cont">
        商家可自行配置优惠券、消费卡等，生成专属的领卡二维码、制作专属的海报。
        <br>
        用户扫码后可免费领取或按商家配置的价格付费获取
      </div>
    </div>
    <div class="title">
      <div>活动设置</div>
      <el-button type="primary" @click="titleaddClick()">新增活动</el-button>
       <el-button type="primary" @click="huodongClick()">活动订单</el-button>
    </div>
    <div class="from">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="品牌" v-if="user_id === 0">
          <el-select v-model="formInline.ping" placeholder="请选择" @change="pingClick">
            <el-option v-for="(item,index) in pinglist" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="门店">
          <el-select v-model="formInline.company_id" placeholder="请选择" @change="menClick">
            <el-option v-for="(item,index) in menData" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="活动状态">
          <el-select v-model="formInline.zhuang" placeholder="请选择" @change="zhuangClick">
            <el-option v-for="(item,index) in zhangData" :key="index" :label="item.name" :value="item.type"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="user_id == 0">
          <el-button type="primary" style="width:80px;margin-left: 15px;" @click="queClick">清空</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="table-cont">
      <el-table
          :max-height="heightmAC"
          :header-cell-style="headCalss"
          :data="listData"
          border
          style="width: 100%">
        <el-table-column
            prop="store_name"
            align="center"
            label="门店">
        </el-table-column>
        <el-table-column
            prop="store_activity_name"
            align="center"
            label="活动名称">
        </el-table-column>
        <el-table-column
            prop="begin_date"
            align="center"
            label="开始时间">
        </el-table-column>
        <el-table-column
            prop="end_date"
            align="center"
            label="结束时间">
        </el-table-column>
        <el-table-column
            align="center"
            label="活动状态">
          <template slot-scope="scope">
            {{ scope.row.status == 0 ? '关闭' : '开启' }}
          </template>
        </el-table-column>
        <el-table-column
            prop="id"
            align="center"
            label="操作">
          <template slot-scope="scope">
            <div class="caoZUO">
              <span @click="eidtClick(scope.row)">编辑 </span>
              <span @click="chaClikc(scope.row)">素材</span>
              <span @click="zhuangCao(scope.row)"> {{ scope.row.status != 0 ? '关闭' : '开启' }}</span>
              <span @click="dingDan(scope.row)">订单</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 新增and编辑 -->
    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="1350px">
      <div class="addStyle">
        <el-form :model="addForm" :rules="rules" ref="addForm" label-width="80px" class="demo-ruleForm">
          <el-form-item label="品牌" prop="pin" v-if="user_id == 0">
            <el-select v-model="addForm.pin" placeholder="请选择品牌" style="width:70%" @change="addPinClik">
              <el-option v-for="(item,index) in pinglist" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="活动门店" prop="men">
            <el-select v-model="addForm.men" placeholder="请选择活动门店" style="width:70%">
              <el-option v-for="(item,index) in addMENdTA" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="活动名称" prop="name">
            <el-input v-model="addForm.name" style="width:70%"></el-input>
          </el-form-item>
          <el-form-item label="活动时间" prop="time">
            <el-date-picker
                style="width:70%"
                v-model="addForm.time"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="优惠券" name="1">
              <el-button type="primary" size="mini" @click="addYou">添加优惠券</el-button>
              <ul class="tableUl">
                <li>
                  <div>优惠券名称</div>
                  <div>优惠券ID</div>
                  <div>优惠券说明</div>
                  <div>库存</div>
                  <div>单人限购</div>
                  <div>原价</div>
                  <div>售价</div>
                  <div>操作</div>
                </li>
                <li v-for="(item,index) in youData" :key="index">
                  <div>
                    <input type="text" placeholder="请输入名称" v-model="item.name">
                  </div>
                  <div>
                    <input type="text" placeholder="请输入ID" v-model="item.id">
                  </div>
                  <div>
                    <input type="text" placeholder="请输入说明" v-model="item.shuo">
                  </div>
                  <div>
                    <input oninput="if(value<0)value='';value=value.replace(/[^0-9\.]/g,'');if(value>999999999)value='';" type="text"
                           placeholder="请输入库存" v-model="item.ku">
                  </div>
                  <div>
                    <input oninput="if(value<0)value='';value=value.replace(/[^0-9\.]/g,'');if(value>999999999)value='';" type="text"
                           placeholder="请填写，0不限购" v-model="item.gou">
                  </div>
                  <div>
                    <input oninput="if(value<0)value='';value=value.replace(/[^0-9\.]/g,'');if(value>999999999)value='';" type="text"
                           placeholder="请填写价格" v-model="item.yuan">
                  </div>
                  <div>
                    <input oninput="if(value<0)value='';value=value.replace(/[^0-9\.]/g,'');if(value>999999999)value='';" type="text"
                           placeholder="请填写，0则免费领取" v-model="item.sou">
                  </div>
                  <div>
                    <span class="caozuoTan" @click="dellROW(index)">删除</span>
                  </div>
                </li>
              </ul>
            </el-tab-pane>
            <el-tab-pane label="次卡" name="2">
              <el-button type="primary" size="mini" @click="addCi">添加次卡</el-button>
              <ul class="tableUl ciUl">
                <li>
                  <div>次卡id</div>
                  <div>次卡名称</div>
                  <div>使用说明</div>
                   <div>库存</div>
                    <div>单人限购</div>
                     <div>原价</div>
                  <div>需支付现金</div>
                  <div>操作</div>
                </li>
                <li v-for="(item,index) in ciData" :key="index">
                  <div>
                    <input type="text" placeholder="请输入ID" v-model="item.id">
                  </div>
                  <div>
                    <input type="text" placeholder="请输入名称" v-model="item.name">
                  </div>
                  <div>
                    <input type="text" placeholder="请输入说明" v-model="item.shuo">
                  </div>
                   <div>
                    <input oninput="if(value<0)value='';value=value.replace(/[^0-9\.]/g,'');if(value>999999999)value='';" type="text"
                           placeholder="请输入库存" v-model="item.ku">
                  </div>
                  <div>
                    <input oninput="if(value<0)value='';value=value.replace(/[^0-9\.]/g,'');if(value>999999999)value='';" type="text"
                           placeholder="请填写，0不限购" v-model="item.gou">
                  </div>
                  <div>
                    <input oninput="if(value<0)value='';value=value.replace(/[^0-9\.]/g,'');if(value>999999999)value='';" type="text"
                           placeholder="请填写价格" v-model="item.yuan">
                  </div>
                  <div>
                    <input oninput="if(value<0)value='';value=value.replace(/[^0-9\.]/g,'');if(value>999999999)value='';" type="text"
                           placeholder="请输入金额" v-model="item.jin">
                  </div>
                  <div>
                    <span class="caozuoTan" @click="dellCi(index)">删除</span>
                  </div>
                </li>
              </ul>
            </el-tab-pane>
          </el-tabs>
          <div class="qun">
            <div>
              全部打包购买折扣
            </div>
            <div>
              <el-input v-model="addForm.zheKou" oninput="if(value<0)value='';value=value.replace(/[^0-9\.]/g,'')"
                        style="width:200px" placeholder="请填写0.01-9.99"></el-input>
            </div>
          </div>
          <div class="que">
            <div @click="addClik">确定</div>
          </div>
        </el-form>
      </div>
    </el-dialog>
    <!-- 素材 -->
    <el-dialog
        title="素材"
        :visible.sync="suTanShow"
        width="800px">
      <div class="suCai">
        <div class="suCai_left">
          <div class="list3" ref="jieImg" :style="{backgroundImage: 'url(' + urlImg + ')' }">
            <canvas id="canvas" ref="bargraphCanvas" :width="canvasWidth" :height="canvasHeight"></canvas>
          </div>
        </div>
        <div class="suCai_rnt">
          <div class="suCai_title">购买二维码</div>
          <div class="erwei">
            <div class="qrcode" ref="qrCodeUrl" id="qrcode"></div>
            <div>
              <el-button type="primary" @click="createPicture">下载二维码</el-button>
            </div>
          </div>
          <div class="upTop">
            <el-upload
                class="upload-demo"
                action="#"
                :http-request="httpRequest"
                :show-file-list="false">
              <el-button size="small" type="primary">上传海报背景</el-button>
            </el-upload>
            <span>海报宽高比9：16，图片会自动按比例缩放</span>
          </div>
          <div class="upBnt">
            <el-button type="primary" @click="baoHai()"  v-loading.fullscreen.lock="fullscreenLoading">保存并下载海报</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import html2canvas from 'html2canvas'
import QRCode from "qrcodejs2";
import {
  brandList,
  storeList,
  fagetActivityList,
  changeStatus,
  saveStampsActivity,
  getActivityDetail,
  getMaterialDetail,
  editMaterial
} from '../../api/index'
// import { Row } from 'element-ui';
export default {
  data() {
    return {
      user_id: null,
      // 查询
      ping: '',
      fullscreenLoading:false,
      formInline: {
        ping: '',
        company_id: '',
        zhuang: 1
      },
      pinglist: [],
      menData: [],
      zhangData: [
        {
          type: 0,
          name: '关闭'
        },
        {
          type: 1,
          name: '开启'
        }
      ],
      //表格
      listData: [],
      heightmAC: window.innerHeight - 330,
      //新增and编辑
      dialogVisible: false,
      addMENdTA: [],
      addForm: {
        men: '',
        name: '',
        time: [],
        zheKou: '',
        pin: ''
      },
      rules: {
        men: [{required: true, message: '请选择活动门店', trigger: 'change'}],
        name: [{required: true, message: '请输入活动名称', trigger: 'blur'}],
        time: [{required: true, message: '请选择活动时间', trigger: 'change'}],
        pin: [{required: true, message: '请选择品牌', trigger: 'change'}]
      },
      activeName: '1',
      youData: [],
      ciData: [],
      //素材
      suTanShow: false,
      urlImg: null,
      canvasWidth: 300, // 画布大小
      canvasHeight: 500,
      extraImgList: [
        {url: require("./logo.png"), x: 0, y: 0, width: 100, height: 100},
        {url: require("./logo.png"), x: 700, y: 100, width: 40, height: 40}
      ],
      myCanvas: null,
      ctx: null,
      imgObject: [],
      imgX: 40, // 图片在画布中渲染的起点x坐标
      imgY: 40,
      imgScale: 0.9, // 图片的缩放大小
      rowId: null,
      rowData: null
    }
  },
  created() {
    this.user_id = JSON.parse(localStorage.getItem('user_id'))
    //判断是否是管理员
    if (this.user_id == 0) {
      this._brandList()
    } else {
      this.menData = JSON.parse(localStorage.getItem('store_list'))
       this.addMENdTA = JSON.parse(localStorage.getItem('store_list'))
      this.ping = JSON.parse(localStorage.getItem('brand_id'))
      if (this.menData.length > 0) {
        this.formInline.company_id = this.menData[0].id
      }
      this._queList()
    }
  },
  mounted() {

  },
  methods: {
    headCalss() {
      return 'background:#f5f7fa'
    },
    //获取品牌
    _brandList() {
      brandList().then(Er => {
        if (Er.data && Er.data.length > 0) {
          this.pinglist = Er.data
          // this._storeList(Er.data[0].id)
          // this.ping = Er.data[0].id
        }
      })
    },
    //获取门店
    _storeList(row) {
      storeList({
        brand_id: row
      }).then(Er => {
        if (Er.return_code == 200) {
          if (Er.data && Er.data.length > 0) {
            this.addMENdTA = Er.data
            this.formInline.company_id = Er.data[0].id
            this.menData = Er.data
          } else {
            this.menData = []
            this.addMENdTA = []
          }
          this._queList()
        }
      })
    },
    //活动订单
    huodongClick(){
       this.$router.push({
        path: "/stamps/stampsAdd",
        query: {
          show:false
        }
      })
    },
    //新增活动
    titleaddClick() {
      this.rowId = null
      this.dialogVisible = true
      this.addForm.zheKou=""
      if (this.$refs.addForm) {
        this.$refs.addForm.resetFields()
        this.youData = []
        this.ciData = []
      }
    },
    //选择品牌
    pingClick(val) {
      this.ping = val
      this._storeList(val)
    },
    //选择门店
    menClick(val) {
      this.formInline.company_id = val
      this._queList()
    },
    //选择状态
    zhuangClick(val) {
      this.formInline.zhuang = val
      this._queList()
    },
    //清空
    queClick() {
      this.formInline.company_id = ''
      this.formInline.ping = ''
    },
    // 状态更改
    zhuangCao(val) {
      console.log(val)
      changeStatus({
        store_activity_id: val.id,
        status: val.status == 1 ? 0 : 1
      }).then(er => {
        if (er.return_code) {
          this.$message({
            message: val.status != 0 ? '关闭成功' : '开启成功',
            type: 'success'
          });
          this._queList()
        }
      })
    },
    //添加选择品牌
    addPinClik(val) {
      storeList({
        brand_id: val
      }).then(Er => {
        if (Er.return_code == 200) {
          if (Er.data && Er.data.length > 0) {
            this.addMENdTA = Er.data
          } else {
            this.addMENdTA = []
          }
        }
      })
    },
    //查询活动列表
    _queList() {
      fagetActivityList({
        brand_id: this.ping,
        activity_id: 6,
        store_id: this.formInline.company_id,
        status: this.formInline.zhuang
      }).then(er => {
        console.log(er)
        if (er.return_code == 200) {
          if (er.data && er.data.length > 0) {
            this.listData = er.data
          } else {
            this.listData = []
          }
        }
      })
    },
    //选项卡切换
    handleClick() {

    },
    //添加优惠券
    addYou() {
      this.youData.push({
        name: '',
        id: '',
        shuo: '',
        ku: '',
        gou: '',
        yuan: '',
        sou: '',
      })
    },
    //删除优惠券
    dellROW(index) {
      this.youData.splice(index, 1)
    },
    //添加次卡
    addCi() {
      this.ciData.push({
        id: '',
        name: '',
        shuo: '',
        jin: '',
         ku: '',
        gou: '',
        yuan:'',
      })
    },
    //删除次卡
    dellCi(index) {
      this.ciData.splice(index, 1)
    },
    // 添加
    addClik() {
      let _this = this
      let zong = true
      if (_this.youData.length == 0 && _this.ciData.length == 0) {
        zong = false
      }
      let is = true
      //判断优惠券是否为空
      let youATTR = []
      if (_this.youData.length > 0) {
        _this.youData.map(Er => {
          youATTR.push({
            type: 1,
            card_id: Er.id,
            card_name: Er.name,
            card_desc: Er.shuo,
            stock: Er.ku,
            limit_buy: Er.gou,
            original_price: Er.yuan,
            current_price: Er.sou
          })
          console.log(Er.gou)
          if (Er.name === '' || Er.id === '' || Er.shuo === '' || Er.ku === '' || Er.gou === '' || Er.yuan === '' || Er.sou === '') {
            is = false
          }
        })
      }
      let ci = true
      //判断次卡是否为空
      console.log(_this.ciData)
      if (_this.ciData.length > 0) {
        _this.ciData.map(Er => {
          youATTR.push({
            type: 2,
            card_id: Er.id,
            card_name: Er.name,
            card_desc: Er.shuo,
            current_price: Er.jin,
            stock: Er.ku,
            limit_buy: Er.gou,
            original_price: Er.yuan,
          })
          if (Er.name == '' || Er.id == '' || Er.shuo == '' || Er.jin == '' || Er.ku === '' || Er.gou === '' || Er.yuan === '') {
            ci = false
          }
        })
      }
      console.log(youATTR)
      _this.$refs['addForm'].validate((valid) => {
        if (valid) {
          if (zong) {
            if (!is) {
              _this.$message({
                message: "当前优惠券不能为空",
                type: "error",
              });
            } else {
              if (!ci) {
                _this.$message({
                  message: "当前次卡不能为空",
                  type: "error",
                });
              }
            }
          } else {
            _this.$message({
              message: "优惠券或次卡必选一项",
              type: "error",
            });
          }
          if (zong && is && ci) {
            let can = {
              id: _this.rowId,
              activity_id: 6,
              brand_id: _this.addForm.pin,
              store_id: _this.addForm.men,
              store_activity_name: _this.addForm.name,
              begin_date: _this.addForm.time[0],
              end_date: _this.addForm.time[1],
              pack_discount: _this.addForm.zheKou,
              card_list: JSON.stringify(youATTR)
            }
            saveStampsActivity(can).then(er => {
              if (er.return_code == 200) {
                _this.$message({
                  message: _this.rowId ? '编辑成功' : '添加成功',
                  type: 'success'
                });
                _this.dialogVisible = false
                _this._queList()
              }
            })
            console.log('接口调用')
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //编辑
    eidtClick(row) {
      this.dialogVisible = true
      if (this.$refs.addForm) {
        this.$refs.addForm.resetFields()
        this.youData = []
        this.ciData = []
      }
      getActivityDetail(
          {
            store_activity_id: row.id
          }
      ).then(Er => {
        console.log(Er)
        this.rowId = Er.data.id
        if (Er.return_code == 200) {
          this.addForm = {
            men: Er.data.store_id,
            name: Er.data.store_activity_name,
            time: [Er.data.begin_date, Er.data.end_date],
            zheKou: Er.data.pack_discount,
            pin: Er.data.brand_id
          }
          if (Er.data.stamps_coupon_list || Er.data.stamps_coupon_list.length > 0) {
            let attr = []
            Er.data.stamps_coupon_list.map(ec => {
              attr.push({
                id: ec.card_id,
                name: ec.card_name,
                shuo: ec.card_desc,
                ku: ec.stock,
                gou: ec.limit_buy,
                yuan: ec.original_price,
                sou: ec.current_price
              })
            })
            this.youData = attr
            let arr = []
            if (Er.data.stamps_card_list && Er.data.stamps_card_list.length > 0) {
              Er.data.stamps_card_list.map(ec => {
                arr.push({
                  id: ec.card_id,
                  name: ec.card_name,
                  shuo: ec.card_desc,
                  jin: ec.current_price,
                   ku: ec.stock,
                  gou: ec.limit_buy,
                  yuan: ec.original_price,
                })
              })
            }
            console.log(arr)
            this.ciData = arr
          }
        }
      })
    },
    _saveStampsActivity() {
    },
    // 生成二维码
    qrcode(url, row) {
      console.log(row)
      let _this = this
      document.getElementById("qrcode").innerHTML = "";
      new QRCode(this.$refs.qrCodeUrl, {
        width: 150, // 二维码宽度，单位像素
        height: 150, // 二维码高度，单位像素
        text: url,
        correctLevel: QRCode.CorrectLevel.H //二维码纠错级别（L,M,Q,H）
      });
      html2canvas(document.querySelector("#qrcode")).then(canvas => {
        var imgData = canvas.toDataURL("image/jpeg"); // 获得图片base64
        console.log(imgData)
        let attr = _this.extraImgList
        attr.map((er) => {
          er.url = imgData
          // if(index == 0){
          //     er.x = row.X
          //     er.y = row.Y
          // }
        })
        _this.extraImgList = attr
        let canvass = _this.myCanvas = _this.$refs.bargraphCanvas;
        if (canvass) {
          _this.ctx = _this.myCanvas.getContext('2d');
          _this.loadImg();
          _this.canvasEventsInit();
        }
      })
    },

    // 上传
    httpRequest(file) {
      let _this = this
      if (_this.checkFile(file.file, 'image')) {
        _this.UpHttp(file.file, 'image').then(er => {
          if (er.data.return_code == 200) {
            // _this.getBase64Image(er.data.data.url,300,500)
            _this.urlImg = er.data.data.url
          }
        })
      }
    },
    //素材
    chaClikc(val) {
      let _this = this;
      _this.rowData = val
      _this.suTanShow = true
      // this.urlImg = require("./you_bg.png"),
      // 获取素材详情
      getMaterialDetail({
        store_activity_id: val.id
      }).then(Er => {
        _this.urlImg = Er.data.background_url ? Er.data.background_url : ''
        _this.qrcode(Er.data.qrcode_url, {
          X: Er.data.location_x ? Er.data.location_x : 20,
          Y: Er.data.location_y ? Er.data.location_y : 20
        })
      })
    },
    loadImg() {
      var _this = this;
      let extraImgList = _this.extraImgList;
      let length = extraImgList.length;
      var imageList = [];
      let count = 0;
      //加载背景图片
      // var isBgLoaded = false;
      var img = new Image();
      var bgImg = extraImgList[0];
      img.src = bgImg.url;
      img.onload = () => {
        imageList.push({img: img, x: bgImg.x, y: bgImg.y, width: bgImg.width, height: bgImg.height});
        ++count;
        if (length > 1) {
          //加载剩余图片
          for (let key = 1; key < length; key++) {
            let item = extraImgList[key];
            let extarImg = new Image();
            extarImg.src = item.url;
            extarImg.onload = () => {
              imageList.push({img: extarImg, x: item.x, y: item.y, width: item.width, height: item.height})
              if (++count >= length) {
                _this.imgObject = imageList;
                _this.drawImage(imageList);
              }
            }
          }
        } else {
          _this.imgObject = imageList;
          _this.drawImage(imageList);
        }
      }
    },
    drawImage(imgList) {
      var _this = this;
      _this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
      for (let i = 0; i < imgList.length; i++) {
        _this.ctx.drawImage(
            imgList[i].img, //规定要使用的图片
            _this.imgX + imgList[i].x * _this.imgScale, _this.imgY + imgList[i].y * _this.imgScale,//在画布上放置图像的 x 、y坐标位置。
            imgList[i].width * _this.imgScale, imgList[i].height * _this.imgScale //要使用的图像的宽度、高度
        );
      }
    },
    // 移动
    canvasEventsInit() {
      var _this = this;
      var canvas = _this.myCanvas;
      canvas.onmousedown = function (event) {
        var imgx = _this.imgX;
        var imgy = _this.imgY;
        console.log(imgx, imgy)
        var pos = {x: event.clientX, y: event.clientY};  //坐标转换，将窗口坐标转换成canvas的坐标
        canvas.onmousemove = function (evt) {  //移动
          canvas.style.cursor = 'move';
          var x = (evt.clientX - pos.x) * 2 + imgx;
          var y = (evt.clientY - pos.y) * 2 + imgy;
          _this.imgX = x;
          _this.imgY = y;
          _this.drawImage(_this.imgObject);  //重新绘制图片
        };
        canvas.onmouseup = function () {
          canvas.onmousemove = null;
          canvas.onmouseup = null;
          canvas.style.cursor = 'default';
        };
      };
      canvas.onmousewheel = canvas.onwheel = function (event) {    //滚轮放大缩小
        var wheelDelta = event.wheelDelta ? event.wheelDelta : (event.deltalY * (-40));  //获取当前鼠标的滚动情况
        if (wheelDelta > 0) {
          _this.imgScale *= 1.1;
        } else {
          if (_this.imgScale > 0.9) {
            _this.imgScale *= 0.9;
          }
        }
        _this.drawImage(_this.imgObject);   //重新绘制图片
        event.preventDefault && event.preventDefault();
        return false;
      };
    },
    //订单
    dingDan(val) {
      this.$router.push({
        path: "/stamps/stampsAdd",
        query: {
          id: val.id,
          brand_id: this.ping,
          store_id: this.formInline.company_id,
          show:true
        }
      })

    },
    // 下载二维码
    createPicture() {
      html2canvas(document.querySelector("#qrcode")).then(canvas => {
        var imgData = canvas.toDataURL("image/jpeg"); // 获得图片base64
        let a = document.createElement("a");
        a.href = imgData;
        a.setAttribute("download", "二维码");
        a.click();
      })
    },
    // 保存海报并下载
    baoHai() {
      let _this = this
      _this.fullscreenLoading = true;
      setTimeout(() => {
        html2canvas(this.$refs.jieImg, {
          useCORS: true,
          allowTaint: true,
        }).then(canvas => {
          var imgUrl = canvas.toDataURL("image/png");
          // 保存
          var str = imgUrl.split(',')[0].split(':')[1].split(';')[0];
          let name = 'name.' + str.split('/')[1]
          console.log(typeof name)
          let file = _this.dataURLtoFile(imgUrl, name)
          if (_this.checkFile(file, 'image')) {
            _this.UpHttp(file, 'image').then(er => {
              if (er.data.return_code == 200) {
                _this.editPai(er.data.data.url)
                _this.fullscreenLoading = false;
                 //下载
                let a = document.createElement("a");
                a.href = imgUrl;
                a.setAttribute("download", "合成");
                a.click();
              }
            })
           
          }

        });
      }, 2000)
    },
    editPai(url) {
      console.log(this.imgX, this.imgY)
      editMaterial({
        store_activity_id: this.rowData.id,
        location_x: this.imgX,
        location_y: this.imgY,
        background_url: this.urlImg,
        material_url: url
      }).then(er => {
        console.log(er)
        if (er.return_code == 200) {
          this.$message({
            message: '编辑成功',
            type: 'success'
          });
          this.suTanShow = false
        }
      })
    },
    dataURLtoFile(dataurl, filename) {//将base64转换为文件
      var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {type: mime});
    }
  }
}
</script>

<style lang="scss" scoped>
.stamps {
  .describe {
    .describe-title {
      height: 40px;
      line-height: 40px;
      font-weight: bold;
    }

    .describe-cont {
      color: #909399;
      line-height: 20px;
      margin-bottom: 10px;
    }
  }

  .title {
    height: 60px;
    display: flex;
    align-items: center;
    width: 100%;

    > div {
      &:nth-child(1) {
        margin-right: 10px;
      }
    }
  }

  .from {
    padding: 15px 0px;
  }

  .caoZUO {
    > span {
      color: #409EFF;
      margin-left: 10px;

      &:nth-child(1) {
        margin-left: 0;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .addStyle {
    .tableUl {
      border-top: 1px solid #DCDFE6;
      border-right: 1px solid #DCDFE6;
      margin-top: 10px;

      > li {
        height: 40px;
        line-height: 40px;
        display: flex;

        > div {
          width: 12.5%;
          border-left: 1px solid #DCDFE6;
          border-bottom: 1px solid #DCDFE6;
          display: flex;
          align-content: center;
          justify-content: center;
          padding: 0px 10px;

          > input {
            width: 95%;
            text-align: center;
            border: none;

            &:focus {
              outline: none; //边框不用border，用outline
            }
          }
        }
      }

      > .bnt {
        > div {
          width: 33.33%;
        }
      }

      .caozuoTan {
        color: #409EFF;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .ciUl {
      > li {
        > div {
          width: 20%;
        }
      }
    }

    .qun {
      display: flex;
      align-items: center;
      margin-top: 30px;

      > div {
        &:nth-child(1) {
          margin-right: 10px;
        }
      }
    }

    .que {
      margin-top: 20px;
      display: flex;
      justify-content: center;

      > div {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;
        background: #409EFF;
        color: #fff;
        border-radius: 4px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.suCai {
  display: flex;

  .suCai_left {
    width: 300px;
    height: 500px;
    border: 1px solid black;
  }

  .suCai_rnt {
    width: calc(100% - 300px);
    padding-left: 30px;

    > .suCai_title {
      margin-bottom: 20px;
      font-size: 16px;
    }

    > .erwei {
      display: flex;
      margin-bottom: 60px;

      > div {
        &:nth-child(1) {
          width: 150px;
          height: 150px;
          margin-right: 20px;
        }

        &:nth-child(2) {
          width: calc(100% - 170px);
        }
      }

      > .qrcode {
      }
    }

    > .upTop {
      margin-bottom: 30px;

      > span {
        color: red;
        margin-left: 10px;
      }
    }

  }
}

#canvas {
  position: absolute;
  z-index: 1;
}

#scream {
  position: absolute;
  width: 300px;
  height: 500px;
}

.list3 {
  width: 300px;
  height: 500px;
  float: right;
  position: relative;
  background-size: 100% 100%;
  background-position: 0 rpx 0 rpx 0 rpx 0 rpx;

}

</style>
